<template>
  <v-row justify="center">
    <v-dialog v-model="shower" max-width="1000px" scrollable>
      <v-card class="pa-2 pa-sm-5" height="100%">
        <v-list-item>
          <v-card-title class="pa-0">{{ data.receiver_name }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            outlined
            color="red"
            @mouseover="show_btn_close = true"
            @mouseleave="show_btn_close = false"
          >
            <v-icon @click="$emit('closedialog')">mdi-close</v-icon>
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index: 10000; bottom: -45px; left: -20px;"
              :value="show_btn_close"
              :content="$t('balloon.close')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000; bottom: -45px; left: -30px;"
              :value="show_btn_close"
              :content="$t('balloon.close')"
              transition="slide-y-transition"
            ></v-badge>
          </v-btn>
        </v-list-item>
        <v-container>
          <div v-if="loaddataprogress === true" class="text-center">
            <v-progress-circular
              :size="50"
              :color="color.theme"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <!-- จำนวน / ผลรวม (ทั้งหมด) -->
            <v-layout justify-center wrap row mb-sm-3 mb-0>
              <v-flex py-sm-0 pa-2 lg5 sm6>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge class="mr-3" color="transparant" :style="iconDashboard">
                          <v-avatar color="#A8D1E7" size="40">
                            <v-icon color="white">mdi-file-document</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <span class="black--text font-weight-regular">
                            {{ $t("toolbar.dashboardoutbox.totaloutbox") }}
                          </span>
                          <h2 class="black--text font-weight-regular my-1">{{ total_outbox }} {{ ($t("toolbar.file")) }}</h2>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex py-sm-0 pa-2 lg5 sm6>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap class="mr-3" color="transparant" :style="iconDashboard_lastMonths">
                          <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>
                          <v-avatar color="#80B7A2" size="40">
                            <v-icon color="white">mdi-file-document</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <span class="black--text font-weight-regular">
                            {{ $t("toolbar.dashboardoutbox.totalfilesize") }}
                          </span>
                          <h2 class="black--text font-weight-regular my-1">{{ convertFileSize(total_filesize) }}</h2>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <!-- จำนวน / ผลรวม (ย้อนหลัง 6 เดือน) -->
            <v-layout justify-center wrap row mb-sm-3 mb-0>
              <v-flex py-sm-0 pa-2 lg5 sm6>
                <v-card hover :style="cardDashborad">
                 <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap bottom class="mr-3" color="#FCEE9E" :style="iconDashboard">
                          <h2 slot="badge" :style="badgeText"> 6 </h2>
                          <v-avatar color="#A8D1E7" size="40">
                            <v-icon color="white">mdi-calendar-month</v-icon>
                          </v-avatar>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <span class="black--text font-weight-regular">
                            {{ $t("toolbar.dashboardoutbox.outbox_last6months") }}
                          </span>
                          <h2 class="black--text font-weight-regular my-1">{{ total_outbox_last6months }} {{ ($t("toolbar.file")) }}</h2>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex py-sm-0 pa-2 lg5 sm6>
                <v-card hover :style="cardDashborad">
                  <v-card-text class="pa-2">
                    <v-list>
                      <v-list-item class="px-md-2 px-0">
                        <v-badge overlap class="mr-3" color="transparant" :style="iconDashboard_lastMonths">
                          <span slot="badge"><v-icon color="#F898A4" size="30" class="ml-n3">mdi-plus-thick</v-icon></span>
                          <v-badge overlap bottom color="#FCEE9E">
                            <h2 slot="badge" :style="badgeText"> 6 </h2>
                            <v-avatar color="#80B7A2" size="40">
                              <v-icon color="white">mdi-calendar-month</v-icon>
                            </v-avatar>
                          </v-badge>
                        </v-badge>
                        <v-list-item-title class="text-wrap">
                          <span class="black--text font-weight-regular">
                            {{ $t("toolbar.dashboardoutbox.filesize_last6months") }}
                          </span>
                          <h2 class="black--text font-weight-regular my-1">{{ convertFileSize(total_filesize_last6months) }}</h2>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>

            <!-- กราฟ -->
            <v-layout justify-center wrap row>
              <v-flex px-3 md10 sx12>
                <div id="chart">
                  <apexchart :height="resolutionScreen < 400 ? 330 : 230" :options="chartOptions" :series="series"></apexchart>
                </div>
                <h4 class="text-center">{{ $t("toolbar.dashboardoutbox.outboxpermonth") }}</h4>
              </v-flex>
            </v-layout>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueApexCharts from "vue-apexcharts";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "data"],
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      series: [{
        name: "count",
        data: [],
      }],
      loaddataprogress: true,
      show_btn_close: false,
      datamonthlyoutbox: [],
      total_outbox: 0,
      total_filesize: 0,
      total_outbox_last6months: 0,
      total_filesize_last6months: 0,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.getDataMonthly();
        }
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    cardDashborad() {
      return "border-radius: 20px; cursor: default; height: 100%; display: flex; align-items: center;";
    },
    iconDashboard() {
      return "padding: 3px; border: 3px solid #A8D1E7; border-radius: 50%;";
    },
    iconDashboard_lastMonths() {
      return "padding: 3px; border: 3px solid #F898A4; border-radius: 50%;";
    },
    badgeText() {
      return "color: black !important; margin-top: -4px;"
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          background: 'transparant',
          animations: {
            enabled: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: ['#F898A4', '#FFC29E', '#FCEE9E','#80B7A2', '#A8D1E7', '#B5B1C8'],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#304758"],
            fontWeight: 900
          }
        },
        stroke: {
          show: true,
          width: 2,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'center',
            },
            distributed: true,
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: this.convertMonth(),
          labels: {
            hideOverlappingLabels: false
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
  },
  methods: {
    async getDataMonthly(){
      this.datamonthlyoutbox = [];
      this.total_outbox_last6months = 0;
      this.total_filesize_last6months = 0;
      var payload = {
        inbox_type: "outbox",
        data_type: "2",
        data_id: this.dataAccountActive.business_info.business_id,
        search_taxid: this.data.receiver_taxid,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DASHBOARD_API + "/api/v1/get_dashboard_info", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log('response',response);
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.data.monthly_usages.length; i++) {
              let data = {};
              data["count_active_file"] = response.data.data.monthly_usages[i]["count_active_file"];
              data["month"] = response.data.data.monthly_usages[i]["month"];
              data["year"] = response.data.data.monthly_usages[i]["year"];
              this.datamonthlyoutbox.push(data);
                          
              this.total_outbox_last6months += response.data.data.monthly_usages[i]["count_active_file"];
              this.total_filesize_last6months += response.data.data.monthly_usages[i]["used_active_storage"];
            }
            this.total_outbox = response.data.data.total_active_file
            this.total_filesize = response.data.data.total_used_active_storage

            this.datamonthlyoutbox = this.datamonthlyoutbox.sort((a, b) => {
              return parseInt(a.year) - parseInt(b.year) || parseInt(a.month) - parseInt(b.month);
            });
            this.series[0].data = this.datamonthlyoutbox.map(item => item.count_active_file);
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    convertFileSize(file_size) {
      let result = 0;
      if (file_size >= 1099511627776) {
        result = (file_size / (1099511627776)).toFixed(2) + ' TB';
      } else if (file_size >= 1073741824) {
        result = (file_size / (1073741824)).toFixed(2) + ' GB';
      } else if (file_size >= 1048576) {
        result = (file_size / (1048576)).toFixed(2) + ' MB';
      } else if (file_size >= 1024) {
        result = (file_size / 1024).toFixed(2) + ' KB';
      } else {
        result = file_size.toFixed(2) + ' B';
      }
      return result;
    },
    convertMonth() {
      // console.log('convertMonth', this.datamonthlyoutbox);
      let monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let convertedData = this.datamonthlyoutbox.map(item => {
        let monthNumber = parseInt(item.month, 10);
        if (monthNumber >= 1 && monthNumber <= 12) {
          return monthAbbreviations[monthNumber - 1] + " " + item.year;
        } else {
          return 'Invalid month';
        }
      });
      return convertedData;
    },
  },
};
</script>
